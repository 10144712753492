export * from './LineMovement.model'
export * from './MatchUp.model'
export * from './PublicData.model'
export * from './Streaks.model'
export * from './HistoricalResults.model'

export enum SECONDARY_NAV {
  STREAKS = 'streaks',
  LINE_MOVEMENT = 'line-movement',
  PUBLIC = 'public',
  MATCH_UP = 'match-up',
  HISTORICAL_RESULTS = 'historical-results',
  TRACKER = 'tracker'
}

export enum SUB_PAGE {
  ALL = "All",
  MONEYLINE = "Moneyline",
  SPREAD = "Spread",
  TOTAL = "Total",
}

export enum GAME_PERIODS {
  FULL_TIME = 'Full Time',
  FIRST_HALF = '1st Half',
  FIRST_QUARTER = '1Q',
  SECOND_QUARTER = '2Q',
  THIRD_QUARTER = '3Q',
  FOURTH_QUARTER = '4Q',
}

export enum RESULT {
  WIN = 'Win',
  LOSS = 'Loss',
  TIE = 'Tie',
  PUSH = 'Push'
}

export enum VENUE {
  HOME = "Home",
  ROAD = "Road"
}

interface BetData {
  bookmaker: string,
  result: RESULT,
  price: {
    open: number,
    current: number,
    close?: number,
    best: number,
  },
  point: {
    open: number,
    current: number,
    close?: number,
    best: number,
  }
  percent_money: number,
  percent_bets: number,
  total_bets: number
}

export interface TeamGameData {
  id: string;
  name: string;
  venue: VENUE;
  result: RESULT;
  score: number;
  h2h?: BetData;
  spreads?: BetData;
  totals?: BetData;
  '1hh2h'?: BetData;
  '1hspreads'?: BetData;
  '1htotals'?: BetData;
  '1qh2h'?: BetData;
  '1qspreads'?: BetData;
  '1qtotals'?: BetData;
  '2qh2h'?: BetData;
  '2qspreads'?: BetData;
  '2qtotals'?: BetData;
  '3qh2h'?: BetData;
  '3qspreads'?: BetData;
  '3qtotals'?: BetData;
  '4qh2h'?: BetData;
  '4qspreads'?: BetData;
  '4qtotals'?: BetData;
}

export interface GameData {
  id: string
  startTime: Date, // Combine startTime and Date
  homeTeam: string,
  awayTeam: string,
  outcome_point_home: number,
  outcome_point_away: number,
  teams: {
    [teamId: string]: TeamGameData
  },
}

export interface DataExplorerBetData {
  [id: string]: {
    hash: string,
    datetime: number,
    outcome_price_home: number
    outcome_point_home: number
    outcome_price_away: number
    outcome_point_away: number
  }
}

export interface DataExplorerScoreData {
  [id: string]: {
    hash: string,
    datetime: number,
    away_team_score: number,
    home_team_score: number,
    home_team_result: 'Win' | 'Loss' | 'Tie',
    away_team_result: 'Win' | 'Loss' | 'Tie',
  }
}

export interface DataExplorerGameData {
  id: string,
  sport_key: string,
  commence_time: string,
  home_team: string,
  away_team: string,
  bet_data: {
    h2h: DataExplorerBetData,
    spreads: DataExplorerBetData,
    totals: DataExplorerBetData
  }
  scores: DataExplorerScoreData
}