export interface StreaksData {
  [teamId: string]: {
    id: string,
    logoPath: string,
    games: {
      opponentId: string,
      isHome: boolean,
      result: GAME_RESULT
    }[]
  }
}

export enum GAME_RESULT {
  WIN = 'Win',
  LOSS = 'Loss',
  TIE = 'Tie'
}