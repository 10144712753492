import React from 'react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  CustomSVGSeries,
} from 'react-vis';

import { ReactComponent as HomeTeamLoss } from './icons/home_team_loss.svg';
import { ReactComponent as HomeTeamWin } from './icons/home_team_win.svg';
import { ReactComponent as HomeGameCurrent } from './icons/home_game_current.svg';
import { ReactComponent as AwayTeamLoss } from './icons/away_team_loss.svg';
import { ReactComponent as AwayTeamWin } from './icons/away_team_win.svg';
import { ReactComponent as AwayGameCurrent } from './icons/away_game_current.svg';

import { mapSportToApiKey, mapSubPageAndTimeDivisionToApiKey } from '..';
import { TeamData, VALID_SPORTS } from '../../../models';
import { GAME_PERIODS, GAME_RESULT, GameData, SUB_PAGE } from '../../../models/common';

import '../../../styles/page.scss';

interface ScatterChartProps {
  allTeams?: TeamData[];
  sport: VALID_SPORTS;
  subPage: SUB_PAGE;
  teamId: string;
  getGamesByTeamIdQuery: UseQuery<QueryDefinition<{teamId: string, marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>
  selectedTimePeriod: GAME_PERIODS;
}

const iconRenderer = (iconType: number) => {
  switch (iconType) {
    case 1:
      return <HomeTeamWin />;
    case 2:
      return <HomeTeamLoss />;
    case 3:
      return <AwayTeamWin />;
    case 4:
      return <AwayTeamLoss />;
    case 5:
      return <HomeGameCurrent />;
    case 6:
      return <AwayGameCurrent />
    default:
      return null;
  }
};

export const HistoricalScatterChart: React.FC<ScatterChartProps> = ({selectedTimePeriod, allTeams, subPage, sport, teamId, getGamesByTeamIdQuery}) => {
  let xMax: number | null, xMin: number | null;

  const getRangeY = () => {
    switch (subPage) {
      case SUB_PAGE.MONEYLINE:
        return { min: -350, max: 350 };
      case SUB_PAGE.SPREAD:
        if (sport === VALID_SPORTS.NHL) {
          return { min: -2.5, max: 2.5 };
        }
        return { min: -10, max: 10 };
      case SUB_PAGE.TOTAL:
        if (sport === VALID_SPORTS.NHL) {
          return { min: 5, max: 7 };
        }
        if (sport === VALID_SPORTS.NFL || sport === VALID_SPORTS.NCAAF) {
          return { min: 36, max: 52 };
        } else {
          return { min: 190, max: 240 };
        }
      default:
        return { min: 0, max: 0 };
    }
  };

  const getYData = (data: any) => {
    const yData = data[mapSubPageAndTimeDivisionToApiKey(subPage, selectedTimePeriod)]
    switch (subPage) {
      case SUB_PAGE.MONEYLINE:
        return yData?.price?.close || yData?.price?.current || yData?.price?.best || yData?.price?.open
      default:
        return yData?.point?.close || yData?.point?.current || yData?.point?.best || yData?.point?.open
    }
  };

  const getYAxis = (data: number) => {
    const y = getYData(data);
    const rangeY = getRangeY();
    if (y <= rangeY.min) {
      return rangeY.min;
    }
    if (y >= rangeY.max) {
      return rangeY.max;
    }
    return y;
  };

  const getGameData = (game: any) => {
    const xAxis = new Date(game.startTime).getTime();
    const yAxis = getYAxis(game.teams[teamId]);
    const isPastGame = new Date().getTime() - new Date(game.startTime).getTime() > 0

    if (!xMax || !xMin) {
      xMax = xAxis;
      xMin = xAxis;
    } else {
      if (xMax < xAxis) {
        xMax = xAxis;
      }
      if (xMin > xAxis) {
        xMin = xAxis;
      }
    }
    let iconType: number;
    if (game.homeTeam === teamId) {
      if(isPastGame){
          if (game.teams[teamId].result === GAME_RESULT.WIN) {
              iconType = 1;
            } else {
                iconType = 2;
            }
      }else{
        iconType = 5
      }
    } else {
      if(isPastGame){
        if (game.teams[teamId].result === GAME_RESULT.WIN) {
            iconType = 3;
        } else {
            iconType = 4;
        }
      } else{
        iconType = 6
      }
    }
    return {
      id: game.id,
      y: yAxis,
      x: xAxis,
      iconType: iconType,
    };
  };

  const { data: allGameData } = getGamesByTeamIdQuery({
    sportKey: mapSportToApiKey(sport).sportKey,
    league: mapSportToApiKey(sport).league,
    teamId,
    marketKey: mapSubPageAndTimeDivisionToApiKey(subPage, selectedTimePeriod),
    includePrices: true,
  });

  xMax = null;
  xMin = null;
  const rangeY = getRangeY();
  const chartData = allGameData?.filter(data => !!getYData(data.teams[teamId])).map((game) => getGameData(game))
  const ONE_DAY = 86400000;
  const axisStyle = { fontWeight: 'bold', fontSize: '8px', fill: 'white' };
  const selectedTeam = allTeams?.find((team) => team.id === teamId);
  if (xMax && xMin) {
    return (
      <div className='team-streaks-container'>
        <div className='team-header'>
          <img src={selectedTeam?.logoPath} alt='' />
          <div>{selectedTeam?.name || teamId}</div>
        </div>
        <XYPlot
          width={450}
          height={360}
          xType='time'

          yDomain={[rangeY.min, rangeY.max]}
          xDomain={[xMin - ONE_DAY*3, xMax + ONE_DAY*3]}
        >
          <HorizontalGridLines style={{ stroke: '#424242' }} />
          <XAxis hideLine tickSize={0} style={axisStyle} />
          <YAxis hideLine tickSize={0} style={axisStyle} />

          <CustomSVGSeries
            data={chartData?.map(
              (dot: { x: number; y: number; iconType: number; id: number }) => {
                return {x: dot.x, y: dot.y, customComponent: ()=>(iconRenderer(dot.iconType)), id: dot.id }
              }
            )}
          />
        </XYPlot>
      </div>
    );
  }
  return <></>;
};
