import React, { useEffect, useState } from "react"
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import { QueryDefinition } from "@reduxjs/toolkit/dist/query"
import { useAppSelector } from "../../hooks"

import { Card, LoadingOverlay } from "../../components/common"
import { DateRange } from "../../components/common/DateSelector"
import { SpreadRange } from "../../components/common/SpreadFilter"
import { GAME_ODDS, TeamData, VALID_SPORTS } from "../../models"
import { SUB_PAGE, GameData, VENUE } from "../../models/common"
import { mapSportToApiKey, mapSubPageToApiKey } from "."

import '../../styles/page.scss'

export interface PublicProps {
  sport: VALID_SPORTS,
  subPage: SUB_PAGE,
  currentOdds: GAME_ODDS,
  dateRange: DateRange,
  setDateRange: (range: DateRange) => void,
  spreadRange: SpreadRange
  allTeams?: TeamData[],
  getAllGamesQuery: UseQuery<QueryDefinition< {marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>,
}

export const Public = (props: PublicProps) => {
  const { sport, subPage, currentOdds, dateRange, setDateRange, spreadRange, allTeams, getAllGamesQuery } = props
  const [ filteredGameData, setFilteredGameData ] = useState<GameData[]>([])
  const selectedTeams = useAppSelector((state) => state.teams.teams)
  
  useEffect(() => {
    // If we're coming from the Line Movement page, load the previous date range
    const previousDateRange = JSON.parse(localStorage.getItem('prevDateRange') || 'null')

    if (previousDateRange) {
      const range = {
        start: new Date(previousDateRange.start),
        end: new Date(previousDateRange.end)
      }

      setDateRange(range)
    }

    localStorage.removeItem('prevDateRange')
  }, [])

  const { 
    data: allGamesData, 
    error: allGamesError, 
    isLoading: allgamesLoading 
  } = getAllGamesQuery({
    sportKey: mapSportToApiKey(sport).sportKey,
    league: mapSportToApiKey(sport).league,
    marketKey: mapSubPageToApiKey(subPage),
    includePrices: true
  })

  useEffect(() => {
    if (!allGamesData?.length) {
      return
    }

    const newData = allGamesData
      .filter(gameData => {
        return (
          // Only get games in the future
          new Date(gameData.startTime).getTime() >= Date.now() &&
          (
            // Filter by sidebar team selector
            Object.values(gameData.teams).reduce((acc, team) => {
              return acc || selectedTeams[team.id]
            }, false)
          ) &&
          ( true
            // // TODO: Filter by sidebar spread selector
            // Object.values(gameData.teams).reduce((acc, team) => {
            //   const minOdds = Math.min(
            //     team[mapSubPageToApiKey(subPage)].best,
            //     team[mapSubPageToApiKey(subPage)].open,
            //     team[mapSubPageToApiKey(subPage)].close
            //   )
            //   const maxOdds = Math.max(
            //     team[mapSubPageToApiKey(subPage)].best,
            //     team[mapSubPageToApiKey(subPage)].open,
            //     team[mapSubPageToApiKey(subPage)].close
            //   )

            //   return minOdds >= spreadRange.low && maxOdds <= spreadRange.high
            // }, true)
          )
        )
      })
      .sort((a,b) => {
        // Sort by date ascending
        return new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      })

    setFilteredGameData(newData)
  }, [allGamesData, dateRange, selectedTeams, spreadRange])

  const calculateDifference = (team: GameData['teams'][string], subPage: SUB_PAGE) => {
    const diff = (team[mapSubPageToApiKey(subPage)]?.percent_money || 0) - (team[mapSubPageToApiKey(subPage)]?.percent_bets || 0)
  
    return (
      <span style={{color: diff > 0 ? '#7eab46' : '#c33636'}}>
        {diff > 0 ? '+' : ''}{diff}%
      </span>
    )
  }
  
  const renderOdds = (props: {team: GameData['teams'][string], subPage: SUB_PAGE, key?: string, showColor?: boolean}) => {
    const {team, subPage, key, showColor} = props
  
    let odds = 0
  
    if (team[mapSubPageToApiKey(subPage)]) {
      const useKey = subPage == SUB_PAGE.MONEYLINE ? 
        'price' :
      subPage === SUB_PAGE.TOTAL ?
        'point' :
      sport === VALID_SPORTS.NBA || sport === VALID_SPORTS.NCAAB ?
        'point' :
        'price'
      
      odds = team[mapSubPageToApiKey(subPage)]?.[useKey][key as 'best' || 'best'] || 0
    }
  
    return (
      <span style={{color: showColor ? (odds > 0 ? '#7eab46' : '#c33636') : undefined}}>
        {odds > 0 ? `+${odds}` : odds}
      </span>
    )
  }
  
  const renderPercentBarChart = (pctValue: number) => (
    <div className="pct-bar-container container-fluid">
      <div className="pct-bar-label">{pctValue}%</div>
      <div className="pct-bar-fill" style={{width: `${pctValue}%`}}></div>
    </div>
  )

  const generatePublicDataTable = (currentSubPage: SUB_PAGE) => (
    <React.Fragment key={currentSubPage}>
      <div className="card-header">{currentSubPage}</div>
      <Card classList="public-data-card">
        <LoadingOverlay
          loading={allgamesLoading}
          error={!!allGamesError}
          noData={!filteredGameData.length}
        >
          <div className="row public-data-header-row">
            <div className="col-1">Scheduled</div>
            <div className="col-3"></div>
            <div className="col-1">Open</div>
            <div className="col-1">Best Odds</div>
            <div className="col-2">% Bets</div>
            <div className="col-2">% Money</div>
            <div className="col-1">Difference</div>
            <div className="col-1"># of Bets</div>
          </div>
          {
            filteredGameData.map((game, idx) => {
              const [team1, team2] = Object.values(game.teams)

              return (
                <div className="row public-data-row" key={idx}>
                  <div className="col-1 float-end">
                    <div>{`${new Date(game.startTime).toLocaleTimeString([], { timeStyle: 'short' })}`}</div>
                    <div>{`${new Date(game.startTime).toLocaleDateString([], { dateStyle: 'short' })}`}</div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex align-items-center">
                      <img src={allTeams?.find(team => team.id === team1.id)?.logoPath} alt="team 1 logo" className="public-page-logo"/>
                      <div>{team1.venue === 'Home' ? game.homeTeam : game.awayTeam}</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={allTeams?.find(team => team.id === team2.id)?.logoPath} alt="team 2 logo" className="public-page-logo"/>
                      <div>{team2.venue === 'Home' ? game.homeTeam : game.awayTeam}</div>
                    </div>
                  </div>
                  <div className="col-1">
                    <div>{renderOdds({team: team1, subPage: currentSubPage, key: 'open'})}</div>
                    <div>{renderOdds({team: team2, subPage: currentSubPage, key: 'open'})}</div>
                  </div>
                  <div className="col-1">
                    <div>{renderOdds({team: team1, subPage: currentSubPage, key: 'best', showColor: true})}</div>
                    <div>{renderOdds({team: team2, subPage: currentSubPage, key: 'best', showColor: true})}</div>
                  </div>
                  <div className="col-2">
                    {renderPercentBarChart(team1[mapSubPageToApiKey(currentSubPage)]?.percent_bets || 0)}
                    {renderPercentBarChart(team2[mapSubPageToApiKey(currentSubPage)]?.percent_bets || 0)}
                  </div>
                  <div className="col-2">
                    {renderPercentBarChart(team1[mapSubPageToApiKey(currentSubPage)]?.percent_money || 0)}
                    {renderPercentBarChart(team2[mapSubPageToApiKey(currentSubPage)]?.percent_money || 0)}
                  </div>
                  <div className="col-1">
                    <div>{calculateDifference(team1, currentSubPage)}</div>
                    <div>{calculateDifference(team2, currentSubPage)}</div>
                  </div>
                  <div className="col-1 align-self-center">
                    {
                      (team1[mapSubPageToApiKey(currentSubPage)]?.total_bets || 0) + 
                      (team2[mapSubPageToApiKey(currentSubPage)]?.total_bets || 0)
                    }
                  </div>
                </div>
              )
            })
          }
        </LoadingOverlay>
      </Card>
    </React.Fragment>
  )

  return (
    <div className="container-fluid public-page">
      {
        Object.values(selectedTeams).reduce((acc, val) => acc && !val, true) ?
        (<div className="no-teams-text" style={{minWidth: '50vw'}}>No teams selected</div>) :
        (() => {
          switch(subPage) {
            case SUB_PAGE.SPREAD:
              return generatePublicDataTable(SUB_PAGE.SPREAD)
            case SUB_PAGE.TOTAL:
              return generatePublicDataTable(SUB_PAGE.TOTAL)
            case SUB_PAGE.MONEYLINE:
            default:
              return generatePublicDataTable(SUB_PAGE.MONEYLINE)
          }
        })()
      }
    </div>
  )
}