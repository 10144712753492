import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TeamData } from '../models'
import { RootState } from '../store'
import { GameData } from '../models/common'

interface TeamsState {
  teams: {
    [teamId: string]: boolean
  },
  dates: {
    [timestamp: string]: {
      [teamId: string]: boolean
    }
  },
  selectedDate: number,
  initialLoadComplete: boolean
}

const initialState = {
  teams: {},
  dates: {},
  selectedDate: new Date().setHours(0,0,0,0),
  initialLoadComplete: false
} as TeamsState

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams(state, action: PayloadAction<TeamData[]>) {
      return {
        teams: action.payload.reduce((acc, team) => {
          acc[team.id] = false

          return acc
        }, {} as TeamsState['teams']),
        dates: {},
        selectedDate: new Date().setHours(0,0,0,0),
        initialLoadComplete: false
      }
    },
    setGameDates(state, action: PayloadAction<GameData[]>) {
      const sortedGames = [...action.payload].sort((a,b) => (new Date(a?.startTime).getTime() - new Date(b?.startTime).getTime()))
      const tempState = {
        ...state,
        teams: {...state.teams},
        dates: sortedGames.reduce((acc, game) => {
          const dateTime = new Date(game?.startTime).setHours(0,0,0,0)

          if (!acc[dateTime]) {
            acc[dateTime] = {}
          }

          acc[dateTime][game.homeTeam] = true
          acc[dateTime][game.awayTeam] = true

          return acc
        }, {} as TeamsState['dates'])
      }

      if (!tempState.initialLoadComplete) {
        tempState.initialLoadComplete = true
        const todayLocalMidnight = String(new Date().setHours(0,0,0,0))
        const todayTeams = tempState.dates[todayLocalMidnight]

        if (todayTeams) {
          Object.keys(todayTeams).forEach(team => {
            tempState.teams[team] = true
          })
        }
      }
      return tempState
    },
    selectTeamsByGameDate(state, action: PayloadAction<number | string>) {
      const teamsByDate = { ...state.dates[String(action.payload)] };
      const tempState = {
        ...state,
        selectedDate: Number(action.payload),
        teams: Object.keys(state.teams).reduce((acc, val) => {
          acc[val] = false;

          return acc;
        }, {} as TeamsState['teams'])
      };

      if (teamsByDate) {
        Object.keys(teamsByDate).forEach((team) => {
          tempState.teams[team] = true;
        })
      }

      return tempState;
    },
    toggleTeam(state, action: PayloadAction<string>) {
      state.teams[action.payload] = !state.teams[action.payload];
    },
    selectTeam(state, action: PayloadAction<string>) {
      state.teams[action.payload] = true;
    },
    deselectTeam(state, action: PayloadAction<string>) {
      state.teams[action.payload] = false;
    },
    selectAll(state) {
      return {
        ...state,
        teams: Object.keys(state.teams).reduce((acc,val) => {
          acc[val] = true;

          return acc;
        }, {} as TeamsState['teams'])
      };
    },
    selectNone(state) {
      return {
        ...state,
        teams: Object.keys(state.teams).reduce((acc,val) => {
          acc[val] = false;

          return acc;
        }, {} as TeamsState['teams'])
      }
    }
  }
})

export const {
  setTeams,
  setGameDates,
  toggleTeam,
  selectTeamsByGameDate,
  selectTeam,
  deselectTeam,
  selectAll,
  selectNone
} = teamsSlice.actions;

export const getAllSelectedTeams = (state: RootState) => state.teams.teams;
export const getAllDatesWithGames = (state: RootState) => Object.keys(state.teams.dates);

export default teamsSlice.reducer;
