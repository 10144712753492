import { useEffect, useState } from "react";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../hooks";

import { HistoricalScatterChart } from "./HistoricalScatterChart";
import { DateRange } from "../../../components/common/DateSelector";
import { SpreadRange } from "../../../components/common/SpreadFilter";
import { GAME_ODDS, TeamData, VALID_SPORTS } from "../../../models";
import { SUB_PAGE, GameData, GAME_PERIODS } from "../../../models/common";

import "../../../styles/page.scss";
import { GamePeriodPicker } from "../../../components/common/GamePeriodPicker";

export interface HistoricalDataProps {
  sport: VALID_SPORTS;
  subPage: SUB_PAGE;
  currentOdds: GAME_ODDS;
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  spreadRange: SpreadRange;
  allTeams?: TeamData[];
  getGamesByTeamIdQuery: UseQuery<QueryDefinition<{teamId: string, marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>,
}

export const HistoricalResults = (props: HistoricalDataProps) => {
  const { sport, subPage, currentOdds, dateRange, setDateRange, spreadRange, allTeams, getGamesByTeamIdQuery} = props;

  const selectedTeams = useAppSelector((state) => state.teams.teams);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<GAME_PERIODS>(GAME_PERIODS.FULL_TIME)

  useEffect(() => {
    // If we're coming from the Line Movement page, load the previous date range
    const previousDateRange = JSON.parse(localStorage.getItem('prevDateRange') || 'null')

    if (previousDateRange) {
      const range = {
        start: new Date(previousDateRange.start),
        end: new Date(previousDateRange.end),
      };

      setDateRange(range);
    }

    localStorage.removeItem("prevDateRange");
  }, []);

  return (
    <>
      <GamePeriodPicker selectedTimePeriod={selectedTimePeriod} setSelectedTimePeriod={setSelectedTimePeriod} />
      <div className="streaks-container w-100">
        {Object.values(selectedTeams).reduce((acc, val) => acc && !val, true) ? (
          <div className="no-teams-text">No teams selected</div>
        ) : (
          <>
            <div className="summary-container">
              <div className="summary-title-bar">
                <div>Historical Results</div>
              </div>
              {Object.keys(selectedTeams)
                .filter((teamId) => {
                  return selectedTeams[teamId];
                })
                .map((teamId) => (
                  <HistoricalScatterChart selectedTimePeriod={selectedTimePeriod} allTeams={allTeams} key={teamId} subPage={subPage} sport={sport} teamId={teamId} getGamesByTeamIdQuery={getGamesByTeamIdQuery} />
                ))}
            </div>
          </>
        )}
      </div>
      </>
  );
};
