import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar, CaretDown } from 'react-bootstrap-icons';
import { useAppSelector } from '../../../hooks';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';
import { LoadingOverlay } from '../LoadingOverlay';

interface DateSpreadPickerProps {
  style?: React.CSSProperties;
  onClickCalendar?: (isOpen: boolean) => void;
  onDateChange: (date: Date) => void;
}

export const DateSpreadPicker = (props: DateSpreadPickerProps) => {
  const {style, onClickCalendar, onDateChange} = props;
  const initialLoadComplete = useAppSelector((state) => state.teams.initialLoadComplete);
  const allDatesWithGames = useAppSelector((state) => state.teams.dates);
  const todayLocalMidnight = new Date().setHours(0, 0, 0, 0);

  const [selectedDate, setSelectedDate] = useState<number>(todayLocalMidnight);

  useEffect(() => {
    !initialLoadComplete && setSelectedDate(todayLocalMidnight);
  }, [initialLoadComplete, todayLocalMidnight]);

  useEffect(() => {
    onDateChange(new Date(selectedDate));
  }, [onDateChange, selectedDate]);

  const DatePickerCustomInput = forwardRef<HTMLButtonElement, any>(({ value, onClick }, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const valueIsToday = new Date().toLocaleDateString('en-us', { day: '2-digit', month: '2-digit', year: 'numeric' }) === value;
    const valueIsYesterday = new Date(Date.now() - 24*60*60*1000).toLocaleDateString('en-us', { day: '2-digit', month: '2-digit', year: 'numeric' }) === value;
    const valueIsDayBeforeYesterday = new Date(Date.now() - 2*24*60*60*1000).toLocaleDateString('en-us', { day: '2-digit', month: '2-digit', year: 'numeric' }) === value;
    const valueIs2DaysBeforeYesterday = new Date(Date.now() - 3*24*60*60*1000).toLocaleDateString('en-us', { day: '2-digit', month: '2-digit', year: 'numeric' }) === value;
    const hideDate = (valueIsToday || valueIs2DaysBeforeYesterday || valueIsYesterday || valueIsDayBeforeYesterday);

    return <button
      className='date-picker'
      onClick={onClick}
      ref={ref}
      style={{borderColor: hideDate ? 'rgba(255,255,255,0.4)' : undefined}}
    >
      {hideDate ? '' : new Date(value).toLocaleDateString('en-us', {month: 'numeric', day: 'numeric'})}
      <Calendar />
      {hideDate && <CaretDown />}
    </button>
  });

  return (
    <div className='date-team-picker d-flex' style={style}>
      {[-3, -2, -1, 0].map(offset => (
        <div
          className={selectedDate === todayLocalMidnight + (offset*24*60*60*1000) ? 'date selected' : 'date'}
          key={offset}
          onClick={() => {
            if (!initialLoadComplete) { return; }

            const timestamp = todayLocalMidnight + (offset*24*60*60*1000);
            setSelectedDate(timestamp);
          }}
        >
          <LoadingOverlay
            loading={!initialLoadComplete}
            style={{minHeight: '1rem', padding: 0, transform: 'scale(0.5)'}}
          >
            {
              new Date(Number(todayLocalMidnight + (offset*24*60*60*1000)))
                .toLocaleDateString('en-us', {weekday: 'short', 'month': 'numeric', 'day': 'numeric'})
                .replace(', ', '.\n')
            }
          </LoadingOverlay>
        </div>
      ))}
      <DatePicker
        dateFormat='MM/dd/yyyy'
        selected={new Date(selectedDate)}
        onChange={(date: Date) => {
          if (date && initialLoadComplete) {
            const timestamp = date.setHours(0, 0, 0, 0);
            setSelectedDate(timestamp);
          }
        }}
        customInput={<DatePickerCustomInput />}
        todayButton='Today'
        onCalendarOpen={() => onClickCalendar && onClickCalendar(true)}
        onCalendarClose={() => onClickCalendar && onClickCalendar(false)}
        filterDate={(date) => {
          return Object
            .keys(allDatesWithGames)
            .map((date) => new Date(Number(date)).toLocaleDateString())
            .includes(String(date.toLocaleDateString()));
        }}
      />
    </div>
  );
}
