import { DataGrid, GridColDef } from '@mui/x-data-grid';
import './index.scss';

interface SpreadTableProps {
  data: Record<string, TableData>;
}

interface PercentTagProps {
  wins: number;
  losses: number;
  draws: number;
}

export type TableData = {
  isHome: boolean;
  spread: number | null;
  mostRecentDate: Date;
  spreadRecord: {
    wins: number;
    losses: number;
    pushes: number;
  };
  homeSpreadRecord: {
    wins: number;
    losses: number;
    pushes: number;
  };
  awaySpreadRecord: {
    wins: number;
    losses: number;
    pushes: number;
  };
};

const PercentTag = ({ wins, losses, draws }: PercentTagProps) => {
  const percentWin = Math.round(100*wins/(wins + losses + draws));

  return (
    <div className={`percent-container ${percentWin >= 70 ? 'high' : percentWin <= 35 ? 'low' : ''}`}>
      <div className='percent'>{percentWin}%</div>
      <div className='record'>{`${wins}-${losses}-${draws}`}</div>
    </div>
  );
};

export const SpreadTable = (props: SpreadTableProps) => {
  const { data } = props;

  const columns: GridColDef[] = [
    { field: 'teamName', headerName: '', width: 190 },
    { field: 'spread',
      headerName: 'Spread',
      type: 'number',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => value > 0 ? `+${value}` : value
    },
    {
      field: 'location',
      headerName: 'Home/Away',
      width: 115,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'homeAwayRecord',
      headerName: 'Home/Away Record',
      width: 170,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
        />
      ),
      sortComparator: (v1, v2) => 100*v1.wins/(v1.wins + v1.losses + v1.pushes) - 100*v2.wins/(v2.wins + v2.losses + v2.pushes)
    },
    {
      field: 'spreadRecord',
      headerName: 'Spread Record',
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
        />
      ),
      width: 170,
      headerAlign: 'center',
      align: 'center',
      sortComparator: (v1, v2) => 100*v1.wins/(v1.wins + v1.losses + v1.pushes) - 100*v2.wins/(v2.wins + v2.losses + v2.pushes)
    }
  ];

  const rows = Object.entries(data).map(([teamName, teamData], index) => {
    const { isHome, spread, spreadRecord, homeSpreadRecord, awaySpreadRecord } = teamData;

    return {
      id: index,
      teamName,
      spread,
      location: isHome ? 'Home' : 'Away',
      homeAwayRecord: isHome ? homeSpreadRecord : awaySpreadRecord,
      spreadRecord
    };
  });

  return <div className='datagrid-container'><DataGrid
    rows={rows}
    columns={columns}
    className='table-container'
    pageSizeOptions={[rows.length]}
    hideFooter
  /></div>;
};
