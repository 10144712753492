import { useEffect, useState } from 'react'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { SingleDatePicker } from '../../components/common/DatePicker'
import { useAppSelector } from '../../hooks'
import { RadialChart } from 'react-vis'

import { Card, LoadingOverlay } from '../../components/common'
import { DateRange } from '../../components/common/DateSelector'
import { SpreadRange } from '../../components/common/SpreadFilter'
import { GAME_ODDS, TeamData, VALID_SPORTS } from '../../models'
import { SUB_PAGE, GameData, TeamGameData, RESULT } from '../../models/common'
import { mapSportToApiKey, mapSubPageToApiKey } from '.'

import '../../styles/page.scss'

export interface MatchUpProps {
  sport: VALID_SPORTS,
  currentOdds: GAME_ODDS,
  dateRange: DateRange,
  setDateRange: (range: DateRange) => void,
  spreadRange: SpreadRange
  allTeams?: TeamData[],
  getGamesQuery: UseQuery<QueryDefinition<{sportKey: string, league: string}, any, never, GameData[], string>>,
  getAllGamesQuery: UseQuery<QueryDefinition< {marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>,
}

export const MatchUp = (props: MatchUpProps) => {
  const { sport, dateRange, setDateRange, allTeams, getGamesQuery, getAllGamesQuery } = props
  const [ currentDate, setCurrentDate ] = useState<Date>(new Date())
  const selectedTeams = useAppSelector((state) => state.teams.teams)

  useEffect(() => {
    // If we're coming from the Line Movement page, load the previous date range
    const previousDateRange = JSON.parse(localStorage.getItem('prevDateRange') || 'null')

    if (previousDateRange) {
      const range = {
        start: new Date(previousDateRange.start),
        end: new Date(previousDateRange.end)
      }

      setDateRange(range)
    }

    localStorage.removeItem('prevDateRange')
  }, [])

  const {
    data: gamesData,
    error: gamesError,
    isLoading: gamesLoading
  } = getGamesQuery(mapSportToApiKey(sport))

  // const {
  //   data: allGamesData,
  //   error: allGamesError,
  //   isLoading: allgamesLoading
  // } = getAllGamesQuery({
  //   sportKey: mapSportToApiKey(sport),
  //   marketKey: mapSubPageToApiKey(subPage)
  // })

  const renderCard = (stats: {win: number, loss: number, push: number} | undefined, loading: boolean, error: boolean, isTotal = false, ) => {
    let win = 0, loss = 0, push = 0
    if (stats) {
      win = stats.win
      loss = stats.loss
      push = stats.push
    }
    const totalAll = win + loss + push

    return (
      <Card classList='stat-card'>
        <LoadingOverlay
          loading={loading}
          error={!!error}
          noData={!stats || win+loss+push===0}
        >
          <div className='text-stats'>
            <div>{isTotal ? 'Over-Under-Push' : 'Win-Loss-Push'} </div>
            <div>{win}-{loss}-{push}</div>
            <div className='win'>{Math.round(100*win/totalAll)}% {isTotal ? 'Over' : 'Wins'}</div>
            <div className='loss'>{Math.round(100*loss/totalAll)}% {isTotal ? 'Under' : 'Loss'}</div>
            <div className='push'>{Math.round(100*push/totalAll)}% Push</div>
          </div>

          <RadialChart
            className='chart'
            radius={50}
            innerRadius={35}
            width={120}
            height={120}
            //@ts-ignore
            data={[
              { angle: push, label: 'Total Push', style: { fill: '#425C98', stroke: '#ffffff' } },
              { angle: loss, label: 'Total Losses', style: { fill: '#C33636', stroke: '#ffffff' } },
              { angle: win, label: 'Total Wins', style: { fill: '#7eab46', stroke: '#ffffff' } }
            ]}
          />
        </LoadingOverlay>
      </Card>
    )
  }

  const renderResultColumn = ({teamId, dataPage}: {teamId: string, dataPage: SUB_PAGE}) => {
    const dataType = mapSubPageToApiKey(dataPage)

    const {
      data: allGamesData,
      error: allGamesError,
      isLoading: allgamesLoading
    } = getAllGamesQuery({
      sportKey: mapSportToApiKey(sport).sportKey,
      league: mapSportToApiKey(sport).league,
      marketKey: dataType,
      includePrices: true // We don't need this, but we can optimize caching by including it
    })

    const allGames = allGamesData
      ?.filter(gameData => {
        return (
          // Filter by sidebar date
          new Date(gameData.startTime).getTime() >= dateRange.start.getTime() &&
          new Date(gameData.startTime).getTime() <= dateRange.end.getTime() &&
          (
            // Filter by sidebar team selector
            Object.values(gameData.teams).reduce((acc, team) => {
              return acc || selectedTeams[team.id]
            }, false)
          ) &&
          ( true
            // // TODO: Filter by sidebar spread selector
            // Object.values(gameData.teams).reduce((acc, team) => {
            //   const minOdds = Math.min(
            //     team[mapSubPageToApiKey(subPage)].best,
            //     team[mapSubPageToApiKey(subPage)].open,
            //     team[mapSubPageToApiKey(subPage)].close
            //   )
            //   const maxOdds = Math.max(
            //     team[mapSubPageToApiKey(subPage)].best,
            //     team[mapSubPageToApiKey(subPage)].open,
            //     team[mapSubPageToApiKey(subPage)].close
            //   )

            //   return minOdds >= spreadRange.low && maxOdds <= spreadRange.high
            // }, true)
          )
        )
      })
      .sort((a,b) => {
        // Sort by date descending
        return new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
      })
      .reduce((acc, game) => {
        const [team1, team2] = Object.values(game.teams)
        return [...acc, team1, team2]
      }, [] as TeamGameData[])
      .filter(teamGame => (
        teamGame.id === teamId
      ))

    const allGameStats = allGames?.reduce((acc, currentGame) => {
      if (currentGame[dataType]?.result === RESULT.WIN) {
        return {...acc, win: acc.win + 1}
      } else if (currentGame[dataType]?.result === RESULT.LOSS) {
        return {...acc, loss: acc.loss + 1}
      } else if (currentGame[dataType]?.result === RESULT.PUSH) {
        return {...acc, push: acc.push + 1}
      }
      return acc
    }, {win: 0, loss: 0, push: 0})

    const lastTenGameStats = allGames?.slice(0,10).reduce((acc, currentGame) => {
      if (currentGame[dataType]?.result === RESULT.WIN) {
        return {...acc, win: acc.win + 1}
      } else if (currentGame[dataType]?.result === RESULT.LOSS) {
        return {...acc, loss: acc.loss + 1}
      } else if (currentGame[dataType]?.result === RESULT.PUSH) {
        return {...acc, push: acc.push + 1}
      }
      return acc
    }, {win: 0, loss: 0, push: 0})

    return (
      <div className='me-3' key={`${teamId}-${dataPage}`}>
        <div className='header data-type'>{dataPage}</div>
        <div className='header'>Season Results</div>
        <div>
          {renderCard(allGameStats, allgamesLoading, allGamesError, dataPage === SUB_PAGE.TOTAL)}
        </div>
        <div className='header'>Last 10 Games</div>
        <div>
          {renderCard(lastTenGameStats, allgamesLoading, allGamesError, dataPage === SUB_PAGE.TOTAL)}
        </div>
      </div>
    )
  }

  return (
    <div className='container-fluid match-up-page'>
      <div className='upcoming-dates-container'>
        <div>Upcoming Dates</div>

        <SingleDatePicker
          selectedDate={currentDate}
          onSelectDate={setCurrentDate}
        />

        <div className='mt-3'>
          {
            currentDate.setHours(0,0,0,0) === new Date().setHours(0,0,0,0) ?
            'Today' :
            ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date(currentDate.setHours(0,0,0,0)).getDay()]
          }
          's Games
        </div>
        {
          gamesLoading ?
          (<Card classList='game-card'>
            <LoadingOverlay
              loading={gamesLoading}
              error={!!gamesError}
            />
          </Card>) :
          !gamesData || !gamesData.length ?
          (<Card classList='game-card'>
            <LoadingOverlay
              loading={gamesLoading}
              error={!!gamesError}
              noData={true}
            />
          </Card>) :
          (gamesData
            ?.filter(game => (
              new Date(game.startTime).getTime() >= currentDate.getTime() &&
              new Date(game.startTime).getTime() <= (currentDate.getTime() + 24*60*60*1000)
            ))
            ?.map(game => (
              <Card classList='game-card' key={game.id}>
                <div className='date'>
                  <div>{new Date(game.startTime).toLocaleDateString([], { dateStyle: 'long' })}</div>
                  <div>{new Date(game.startTime).toLocaleTimeString([], { timeStyle: 'short' })}</div>
                </div>
                <div>
                  <div className='team-row'>
                    <img src={allTeams?.find(team => team.id === Object.keys(game.teams)[0])?.logoPath} alt='Team logo' />
                    <div>{allTeams?.find(team => team.id === Object.keys(game.teams)[0])?.name}</div>
                  </div>
                  <div className='team-row'>
                    <img src={allTeams?.find(team => team.id === Object.keys(game.teams)[1])?.logoPath} alt='Team logo' />
                    <div>{allTeams?.find(team => team.id === Object.keys(game.teams)[1])?.name}</div>
                  </div>
                </div>
              </Card>
            ))
          )
        }
      </div>

      <div className='team-entry-container'>
        {
          Object.values(selectedTeams).reduce((acc, val) => acc && !val, true) ?
          (<div className='no-teams-text' style={{minWidth: '50vw'}}>No teams selected</div>) :
          (Object.entries(selectedTeams).filter(([teamId, enabled]) => enabled).map(([teamId, enabled]) => (
            <div className='team-entry' key={teamId}>
              <div className='team-header'>
                  <img src={allTeams?.find(team => team.id === teamId)?.logoPath} alt='Team logo' />
                  <div>{allTeams?.find(team => team.id === teamId)?.name || teamId}</div>
                </div>
              <div className='d-flex'>

              {
                [SUB_PAGE.MONEYLINE, SUB_PAGE.SPREAD, SUB_PAGE.TOTAL].map(dataPage => (
                  renderResultColumn({teamId, dataPage})
                ))
              }
            </div>
          </div>
          )))
        }
      </div>
    </div>
  )
}