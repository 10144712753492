import './GamePeriodPicker.scss'
import { GAME_PERIODS } from '../../../models/common'

interface GamePeriodPickerProps {
  selectedTimePeriod: GAME_PERIODS, 
  setSelectedTimePeriod:  React.Dispatch<React.SetStateAction<GAME_PERIODS>>
}

export const GamePeriodPicker = (props: GamePeriodPickerProps) => {
  const {selectedTimePeriod, setSelectedTimePeriod} = props
  
  return (
    <div className="game-dividion-selector">
        {
          Object.values(GAME_PERIODS).map(period => (
            <div
              key={period}
              className={`game-dividion-tab ${selectedTimePeriod === period ? 'selected' : ''}`}
              onClick={() => {setSelectedTimePeriod(period)}}
            >
              {period}
            </div>
          ))
        }
    </div>
  )
}